<template>
    <div>
        <div class="holder">
            <div class="landlord-infor-read" v-if="landlordDetails === 'view'">
                <div class="tab-holder">
                  <button :class="[tab === 1 ? 'activeTab' : '']" class="btn gen" @click="tab = 1">General infomation</button>
                  <button :class="[tab === 2 ? 'activeTab' : '']" class="btn prop" @click="tab = 2">Properties</button>
                </div>
            </div>
            <div class="detail-tabs-holder" v-if="tab === 2 && propertyDetails">
              <div class="detail-tabs">
                <button v-for="item in pills"
                  :key="item.name" class="btn" :class="[pillsName === item.name ? 'active-pills' : '']" @click="pillsName = item.name">{{ item.name }}</button>
              </div>
            </div>
            <div v-if="tab === 1">
              <div class="" v-if="landlordDetails === 'view'">    
                <div class="email-container info-holder">
                  <div class="review-top-text mb-4">
                    <h5>General Infomation</h5>
                    <h6 @click="landlordDetails = 'edit'">Edit</h6>
                  </div>
                  <div class="row general-info-content">
                    <div class="col-md-6">
                      <h6>Name:</h6>
                      <h5>Afinwaketung Oluremidapo</h5>
                    </div>
                    <div class="col-md-6">
                      <h6>Email address:</h6>
                      <h5>Afinwaketung.oluremi@gmail.com</h5>
                    </div>
                    <div class="col-md-6">
                      <h6>Phone number:</h6>
                      <h5>08034567389</h5>
                    </div>
                    <div class="col-md-6">
                      <h6>Landlord Address:</h6>
                      <h5>43 Oladipo close Lagos, Nigeria</h5>
                    </div>
                  </div>
                </div>
              </div>
              <div  v-if="landlordDetails === 'edit'">
                <div class="update-top-text" style="display: block;">
                  <h4>Edit landlord’s information</h4>
                  <h6>Update landlord details as required</h6>
                </div>

                <div class="form">
                  <div class="input-group">
                    <BaseInput
                      placeholder="Michael"
                      label="First Name"
                      :value="firstName"
                      @input="firstName = $event"
                      :errorMessage="errorMessage.firstName"
                      @blur="validateFirstName"
                    />
                    <BaseInput
                      label="Last Name"
                      placeholder="Ndubuisi"
                      :value="lastName"
                      @input="lastName = $event"
                      :errorMessage="errorMessage.lastName"
                      @blur="validateLastName"
                    />
                  </div>
                  <div class="input-group">
                    <BaseInput
                      type="email"
                      placeholder="example@gmail.com"
                      label="Email Address"
                      :value="email"
                      @input="email = $event"
                      :errorMessage="errorMessage.email"
                      @blur="validateEmail"
                    />

                    <BaseInput
                      label="Phone number"
                      placeholder="+2348 1437 434"
                      :value="phone"
                      type="number"
                      @input="phone = $event"
                      :errorMessage="errorMessage.phone"
                      @blur="validatePhone"
                    />

                  </div>

                  <BaseInput
                    type="textarea"
                    label="Landlord address"
                    placeholder="Enter full address"
                    :value="address"
                    @input="address = $event"
                    :errorMessage="errorMessage.address"
                    @blur="validateLandlordAddress"
                  />
                </div>

                <div class="btn-container">
                  <BaseButtton type="text" @click="tab = 1; landlordDetails = 'view'; pillsName = 'Overview'">Cancel</BaseButtton>
                  <BaseButtton :disabled="updatingLandlord">
                    {{ updatingLandlord ? "Processing..." : "Update" }}
                  </BaseButtton>
                </div>
              </div>
            </div>

            <div class="" v-if="tab === 2 && !propertyDetails &&  pillsName === 'Overview'">
              <div class="update-top-text" style="display: block;">
                  <h4>Properties</h4>
                  <h6>View and manage the list of properties owned by the landlord</h6>
              </div>
              <div class="email-container info-holder">
                <div class="general-info-content mb-0">
                  <div class=" mb-0">
                    <div class="prop-review-holder">
                      <div class="indicator">
                        <h4>G</h4>
                        <div >
                          <h5>Three bedroom flat</h5>
                          <h6>25 Lokoja road Abaji Fct, Nigeria</h6>
                          <h6>3 Units</h6>
                        </div>
                        
                      </div>
                      <div>
                        <img class="dropdown-toggle" src="../assets/icons/menu-icon.svg" alt="menu" data-toggle="dropdown" aria-expanded="false">
                        <div class="dropdown-menu">
                          <a @click="viewLandlordDetails()" class="dropdown-item" href="#">View details</a>
                          <a class="dropdown-item" href="#">Add property</a>
                          <a class="dropdown-item" href="#">Generate report</a>
                          <a class="dropdown-item" href="#">Delete</a>
                        </div>
                      </div>
                    </div>
                  </div>        
                </div>
              </div>

              <div class="email-container info-holder">
                <div class="general-info-content mb-0">
                  <div class=" mb-0">
                    <div class="prop-review-holder">
                      <div class="indicator">
                        <h4>H</h4>
                        <div >
                          <h5>Three bedroom flat</h5>
                          <h6>25 Lokoja road Abaji Fct, Nigeria</h6>
                          <h6>3 Units</h6>
                        </div>
                        
                      </div>
                      <img src="../assets/icons/menu-icon.svg" alt="menu">
                    </div>
                  </div>        
                </div>
              </div>

              <div class="no-prop-add" @click="skip = false">
              <h2>+</h2>
              <h6>Add Property</h6>
            </div>
            </div>

            <div class="" v-if="tab === 2 && propertyDetails && !readyEdit && pillsName === 'Overview'">
              <div class="update-top-text">
                  <div>
                    <h4>Properties</h4>
                    <h6>View key details and status of this property</h6>
                  </div>
                  <span @click="triggerEditProperty()">Edit</span>
              </div>
              <div class="property-details-view-holder property-basic-info">
                <h4>Basic info</h4>
                <div class="row">
                  <div class="col-md-6">
                    <div class="mb-4">
                      <h6>Property title</h6>
                      <h5>The Property title here</h5>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="mb-4">
                      <h6>Property type</h6>
                      <h5>Residential</h5>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="mb-4">
                      <h6>Property subtype</h6>
                      <h5>Duplex</h5>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="mb-4">
                      <h6>Business intent</h6>
                      <h5>Rental</h5>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="mb-4">
                      <h6>State</h6>
                      <h5>Cross River</h5>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="mb-4">
                      <h6>City</h6>
                      <h5>Calabar</h5>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="mb-4">
                      <h6>Address</h6>
                      <h5>24 Calabar Enugu Road Cross River.</h5>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="">
                      <h6>Description</h6>
                      <h5>Lorem ipsum dolor sit amet consectetur. Tortor laoreet volutpat massa felis leo tortor. Dolor non nulla laoreet vel nullam scelerisque nulla aliquam sed. Volutpat ultrices porta t</h5>
                    </div>
                  </div>
                </div>
              </div>
              <div class="property-details-view-holder property-units mt-5">
                <h4>Units</h4>
                <div class="row">
                  <div class="col-6">
                    <div class="mb-4">
                      <h6>Unit name</h6>
                      <h5>Main Unit 1</h5>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="mb-4">
                      <h6>Unit Amount</h6>
                      <h5>₦100,000</h5>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="mb-4">
                      <h6>No of bedrooms</h6>
                      <h5>3</h5>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="mb-4">
                      <h6>No of bathrooms</h6>
                      <h5>2</h5>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="mb-2">
                      <h6>Staus</h6>
                      <h5>Vacant</h5>
                    </div>
                  </div>
                </div>
                <hr>

                <div class="row">
                  <div class="col-6">
                    <div class="mb-4">
                      <h6>Unit name</h6>
                      <h5>Main Unit 1</h5>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="mb-4">
                      <h6>Unit Amount</h6>
                      <h5>₦100,000</h5>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="mb-4">
                      <h6>No of bedrooms</h6>
                      <h5>3</h5>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="mb-4">
                      <h6>No of bathrooms</h6>
                      <h5>2</h5>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="mb-2">
                      <h6>Staus</h6>
                      <h5>Vacant</h5>
                    </div>
                  </div>
                </div>
                
                
              </div>

              
            </div>
            <div class="form" v-if="tab === 2 && propertyDetails && readyEdit && pillsName === 'Overview'">
                <div class="email-container input-full">
                  <BaseInput
                    type="text"
                    placeholder="Eg: newly built 3 bedroom flat"
                    label="Title"
                    :value="propertyTitle"
                    @input="propertyTitle = $event"
                    :errorMessage="errorMessage.title"
                    @blur="validateTitleInput"
                  />
                </div>

                
                <div class="input-group">
                  <InputSelect
                    label="Property Type"
                    @select-option="propertyType = $event"
                    @blur="validatePropertyType"
                    :value="propertyType"
                    :errorMessage="errorMessage.propertyType"
                    :loading="loadingPropertyType"
                  >
                    <option
                      v-for="propertyType in propertyTypes"
                      :key="propertyType.id"
                      :value="propertyType.type"
                      >{{ propertyType.type }}</option
                    >
                  </InputSelect>
                  <InputSelect
                    label="Property subtype"
                    @select-option="propertySubType = $event"
                    @blur="validatePropertySubType"
                    :value="propertySubType"
                    :errorMessage="errorMessage.propertySubType"
                    :loading="loadingPropertySubType"
                  >
                    <option
                      v-for="subType in propertySubTypes"
                      :key="subType.id"
                      :value="subType.subtype"
                      >{{ subType.subtype }}</option
                    >
                  </InputSelect>
                  
                </div>

                <div class="input-group">
                  <InputSelect
                    label="Business Type"
                    @select-option="typeofbusiness = $event"
                    @blur="validateBusinessType"
                    :value="typeofbusiness"
                    :errorMessage="errorMessage.typeofbusiness"
                  >
                    <option selected disabled>Select</option>
                    <option  v-for="item in businesstypenumber"
                      :key="item.id"
                      :value="item.id">{{ item.businesstype }}</option>
                  </InputSelect>
                </div>
                <div class="input-group">
                  <InputSelect
                    label="State"
                    @select-option="location = $event"
                    @blur="validateLocation"
                    :value="location"
                    :errorMessage="errorMessage.location"
                    :loading="loadingLocation"
                  >
                    <option
                      v-for="state in sortedStates"
                      :key="state.id"
                      :value="state.name"
                      >{{ state.name }}</option
                    >
                  </InputSelect>

                  <InputSelect
                    label="City"
                    @select-option="city = $event"
                    @blur="validateCity"
                    :value="city"
                    :errorMessage="errorMessage.city"
                    :loading="loadingCity"
                  >
                    <option
                      v-for="city in sortedCity"
                      :key="city.id"
                      :value="city.name"
                      >{{ city.name }}</option
                    >
                  </InputSelect>
                  
                </div>

                <div class="email-container input-full">
                  <BaseInput
                    type="text"
                    placeholder="Enter property accurate address"
                    label="Property address"
                    :value="propertyAddress"
                    @input="propertyAddress = $event"
                    :errorMessage="errorMessage.propertyAddress"
                    @blur="validatePropertyAddress"
                  />
                </div>
                <div class="input-group">
                  <BaseInput
                  type="textarea"
                  placeholder="Provide landmarks or directions, a detailed property description, and any additional remarks."
                  label="Property description"
                  :value="description"
                  @input="description = $event"
                  :errorMessage="errorMessage.description"
                  @blur="validateDescription"
                />
                </div>
                <div class="addNewUnit">
                  <div class="unit-text-button">
                    <h5>Units</h5>
                    <button class="btn" @click="addForm()"><span>+</span> Add unit</button>
                  </div>
                  <h6>Add the individual units within this property. Each unit can be tracked and updated separately.</h6>
                </div>
              
                <div v-for="(form, index) in forms" :key="index">
                  <div class="unit-count-holder">
                    <h3>Unit {{ index + 1 }}</h3>
                    <div>
                      <small>Save</small>
                      <img src="../assets/icons/trash.svg" alt="delete">
                    </div>
                  </div>
      
                  <form @submit.prevent="submitForm(index)">
                    <div class="input-group">
                      <div class="row">
                        <div class="col-md-4">
                          <label for="name">Unit name:</label>
                          <input class="form-control dynamic-input" id="name" type="text" v-model="form.name" placeholder="Eg. Main" />
                        </div>
                        <div class="col-md-4">
                          <label for="price">Unit Amount:</label>
                          <input class="form-control dynamic-input" id="price" type="text" v-model="form.amount" placeholder="Enter unit amount" />  
                        </div>
                        <div class="col-md-4">
                          <label for="bedrooms">Number of bedrooms:</label>
                          <select class="form-control dynamic-input" id="bedroom" v-model="form.noOfBedroom">
                            <option  value="" selected disabled>Select</option>
                            <option  value="1">1</option>
                            <option value="2">2</option>
                            <option class="3">3</option>
                            <option class="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                          </select>
                        </div>
                        <div class="col-md-4">
                          <label for="bedrooms">Number of bathrooms:</label>
                          <select class="form-control dynamic-input" id="bathroom" v-model="form.noOfBathroom">
                            <option  value="" selected disabled>Select</option>
                            <option  value="1">1</option>
                            <option value="2">2</option>
                            <option class="3">3</option>
                            <option class="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                          </select>
                        </div>
                        <div class="col-md-4">
                          <label for="status">Status:</label>
                          <select class="form-control dynamic-input" name="status" id="status" v-model="form.status">
                            <option value="" disabled>Select status</option>
                            <option
                              v-for="status in propertyStatuses"
                              :key="status"
                              :value="status"
                              >{{ status }}</option
                            >
                          </select>
                          
                        </div>
                        
                      </div>
                    </div>
                    <!-- <div>
                      <label for="name">Name:</label>
                      <input class="form-control dynamic-input" type="text" v-model="form.name" />
                    </div>

                    <div>
                      <label for="email">Email:</label>
                      <input class="form-control dynamic-input" type="email" v-model="form.email" />
                    </div> -->

                    <!-- <button type="submit">Submit Form {{ index + 1 }}</button> -->
                  </form>
                </div>
                <div class="green-yellow-btn">
                  <button class="btn save">Save</button>
                  <button class="btn cancel">Cancel</button>
                </div>
            </div>

            <div>
              <p>tab: {{ tab }}</p>
              <p>Prop Details: {{ propertyDetails }}</p>
              <p>ready Edit: {{ readyEdit }}</p>
              <p>Pills name: {{ pillsName }}</p>

            </div>

            <div class="form" v-if="tab === 2 && !propertyDetails && pillsName === 'Documents'">
              <div class="update-top-text" style="display: block;">
                  <h4>Upload property documents</h4>
                  <h6>Please upload all required documents to ensure a smooth and timely verification process. You can skip for now, but note that skipping may delay verification.</h6>
              </div>
              <div class="upload-holder">
                <div class="holding-upload">
                  <div class="">
                    <h4>Proof of ownership</h4>
                      <p>
                        <img src="../assets/icons/file.svg" alt="file">
                        proof of ownwership.docx
                      </p>
                      <h6>Pending verification</h6>
                  </div>
                  <button class="btn">Upload</button>
                </div>
              </div>

              <div class="upload-holder">
                <div class="holding-upload">
                  <div class="">
                    <h4>Proof of address</h4>
                      <p>
                        <img src="../assets/icons/file.svg" alt="file">
                        proof of address.docx
                      </p>
                      <h6>Pending verification</h6>
                  </div>
                  <button class="btn">Upload</button>
                </div>
              </div>

              <div class="upload-holder">
                <div class="holding-upload">
                  <div class="">
                    <h4>Property images</h4>
                      <p>
                        <img src="../assets/icons/file.svg" alt="file">
                        seating room.png
                      </p>
                      <h6>Pending verification</h6>
                  </div>
                  <button class="btn">Upload</button>
                </div>
              </div>
              <div class="btn-container">
              <BaseButtton type="text"
                >Cancel</BaseButtton
              >
              <BaseButtton @click="validateForm2">Save</BaseButtton>
            </div>
            </div>

            <div class="form" v-if="tab === 2 && pillsName === 'Rent info'">
              <div class="rent-info-holder">
                <div class="update-top-text" style="display: block;">
                  <h4>Rent information</h4>
                  <h6>Check the comprehensive rental details for this property.</h6>
                </div>

                <div class="rent-info-wrap">
                  <h4>Guaranteed rent</h4>
                  <div class="row">
                    <div class="col-6 info-rent">
                      <h6>Rate</h6>
                      <h5>₦100,000</h5>
                    </div>
                    <div class="col-6 info-rent">
                      <h6>Date</h6>
                      <h5>Jun 10, 2024</h5>
                    </div>
                    <div class="col-6 info-rent">
                      <h6 class="mb-0">Unit affected</h6>
                      <select class="form-control info-input" id="bathroom">
                        <option  value="1">Suit A</option>
                        <option value="2">Suit B</option>
                      </select>
                    </div>
                    
                  </div>
                </div>

                <div class="rent-info-wrap">
                  <h4>Harmonized  rent</h4>
                  <div class="row">
                    <div class="col-6 info-rent">
                      <h6>Rate</h6>
                      <h5>₦100,000</h5>
                    </div>
                    <div class="col-6 info-rent">
                      <h6>Date</h6>
                      <h5>Jun 10, 2024</h5>
                    </div>
                    <div class="col-6 info-rent">
                      <h6 class="mb-0">Unit affected</h6>
                      <select class="form-control info-input" id="bathroom">
                        <option  value="1">Suit A</option>
                        <option value="2">Suit B</option>
                      </select>
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
        </div>
    </div>
</template>


<script>
// import CF from "../helpers/currencyFormatter";
// import Loader from './Loader';
import properties from "../services/properties";
import BaseButtton from "../components/UI/BaseButtton.vue";
import BaseInput from "../components/form/BaseInput.vue";
import InputSelect from "../components/form/InputSelect.vue";

// import $ from "jquery";
export default {
  name: "LandlordPropertyViewEdit",
  components: {
    // Loader,
    BaseInput,
    BaseButtton,
    InputSelect,
  },
  data() {
    return {
      loading: false,
      readyEdit: false,
      loadingPropertyType: false,
      loadingPropertySubType: false,
      loadingCity: false,
      loadingLocation: false,
      updatingLandlord: false,
      propertyTypes: [],
      propertyTitle: "",
      propertySubTypes: [],
      propertySubType: "",
      squareFootage: "",
      description: "",
      propertyValue: "",
      propertyType: "",
      noOfBedroom: "",
      noOfBathroom: "",
      noOfToilets: "",
      states: [],
      city: "",
      cities: [],
      businesstypenumber: '',
      propertyStatuses: [],
      userID: '',
      tab: 1,
      landlordDetails: 'view',
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      address: "",
      typeofbusiness: "",
      location: "",
      propertyAddress: "",
      errorMessage: {
        title: "",
        propertyTitle: "",
        noOfBedroom: "",
        noOfBathroom: "",
        noOfToilets: "",
        propertyType: "",
        propertySubType: "",
        squareFootage: "",
        description: "",
        // leaseOrRent: "",
        typeofbusiness: "",
        status: "",
        propertyValue: "",
        form: "",
        firstName: "",
        propertyAddress: "",
        lastName: "",
        email: "",
        phone: "",
        amount: "",
        amount2: "",
        address: "",
        age: "",
        gender: "",
        location: "",
        city: "",
        // evidenceServerFileName: "",
        propertyImageFile: "",
        evidenceFile: "",
      },
      propertyDetails: false,
      pillsName: 'Overview',
      pills: [
        {
          name: 'Overview'
        },
        {
          name: 'Documents'
        }
        ,
        {
          name: 'Rent info'
        }
        ,
        {
          name: 'Onboarding progress'
        }
      ],
      forms: [
        {
          name: '',
          amount: '',
          noOfBedroom: '',
          noOfBathroom: '',
          status: '',
        },
      ],
      
    };
  },
  created() {
    const user = this.$store.getters.getUser;
    this.userID = user.id;

  },
  mounted() {
    this.getPropertyStatuses();
  },
  beforeDestroy() {
  },
  methods: {
    addForm() {   
      const lastForm = this.forms[this.forms.length - 1]; // Get the last form
      // Validate if all fields of the last form are filled
      if (!lastForm.name || !lastForm.amount || !lastForm.noOfBedroom || !lastForm.noOfBathroom || !lastForm.status) {
        this.$toast.error("Please fill in all fields in the unit form before adding a new form.");
        return;
      }
      // Add a new form if validation passes
      this.forms.push({ name: '', amount: '', noOfBedroom: '',  noOfBathroom: ''});
      console.log("Formssss", this.forms);  // Check if a new form is added
    },
    addLandlord() {
      this.$router.push("/user/register-landlord");
    },
    triggerEditProperty() {
      this.readyEdit = true;
    },
    viewLandlordDetails() {
      this.propertyDetails = true;
    },
    validateFirstName() {
      if (this.firstName.trim() === "") {
        this.errorMessage.firstName = " Please input landlord's first name";
        return false;
      }
      this.errorMessage.firstName = "";
      return true;
    },

    validateLastName() {
      if (this.lastName.trim() === "") {
        this.errorMessage.lastName = " Please input landlord's last name";
        return false;
      }
      this.errorMessage.lastName = "";
      return true;
    },
    validateEmail() {
      let regex = new RegExp("[a-z0-9]+@[a-z]+.[a-z]{2,3}");

      if (this.email.trim() === "") {
        this.errorMessage.email = " Email required!";
        return false;
      }

      if (!regex.test(this.email)) {
        this.errorMessage.email = "Invalid Email";
        return false;
      }

      this.errorMessage.email = "";
      return true;
    },
    validatePhone() {
      if (this.phone.trim() === "") {
        this.errorMessage.phone = " Number required!";
        return false;
      }
      if (
        isNaN(this.phone.trim()) ||
        this.phone.trim().length < 11 ||
        Number(this.phone.trim()) < 0
      ) {
        this.errorMessage.phone = " Invalid number";
        return false;
      }

      this.errorMessage.phone = "";
      return true;
    },
    validateLandlordAddress() {
      if (this.address.trim() === "") {
        this.errorMessage.address = " Please input landlord's address";
        return false;
      }
      this.errorMessage.address = "";
      return true;
    },
    validateTitleInput() {
      if (this.propertyTitle.trim() === "") {
        this.errorMessage.title = " Please input title";
        return false;
      }
      this.errorMessage.title = "";
      return true;
    },
    validateBusinessType() {
      if (this.typeofbusiness.trim() === "") {
        this.errorMessage.typeofbusiness = " Please select business type";
        return false;
      }
      this.errorMessage.typeofbusiness = "";
      return true;
    },
    validatePropertyType() {
      if (this.propertyType === "") {
        this.errorMessage.propertyType = "Please select a property type";
        return false;
      }

      this.errorMessage.propertyType = "";
      return true;
    },
    validatePropertySubType() {
      if (this.propertySubType === "") {
        this.errorMessage.propertySubType = "Please select value";
        return false;
      }

      this.errorMessage.propertySubType = "";
      return true;
    },
    validatePropertyAddress() {
      if (this.propertyAddress.trim() === "") {
        this.errorMessage.propertyAddress = " Please input property address";
        return false;
      }
      this.errorMessage.propertyAddress = "";
      return true;
    },
    validateDescription() {
      if (this.description.trim() === "") {
        this.errorMessage.description = " Property description required!";
        return false;
      }
      this.errorMessage.description = "";
      return true;
    },
    getPropertyTypes() {
      this.loadingPropertyType = true;
      //fetch properties
      properties
        .fetchPropertyType()
        .then((data) => {
          //fetch subproperty on first load

          // this.getPropertySubTypes();
          this.propertyTypes = data.propertytypes;
          this.loadingPropertyType = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getPropertyStatuses() {
      this.loadingStatus = true;
      properties.fetchPropertyStatuses().then((data) => {
        this.propertyStatuses = data.propertystatus;
        this.loadingStatus = false;
      });
    },
    getBusinessTypes() {
      //fetch properties
      properties
        .fetchBusinessTypes()
        .then((data) => {
          // console.log("Business Data", data)
          if (data.success === "success") {
            this.businesstypenumber = data.businesstype;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getPropertySubTypes(id) {
      this.loadingPropertySubType = true;
      properties
        .fetchPropertySubType(id)
        .then((data) => {
          this.propertySubTypes = data.propertysubtypes;
          this.loadingPropertySubType = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    validateCity() {
      if (this.city === "") {
        this.errorMessage.city = "Please select city";
        return false;
      }

      this.errorMessage.city = "";
      return true;
    },
    validateLocation() {
      if (this.location === "") {
        this.errorMessage.location = "Please select state";
        return false;
      }

      this.errorMessage.location = "";
      return true;
    },
    getStates() {
      this.loadingLocation = true;
      properties
        .fetchStates()
        .then((data) => {
          this.loadingLocation = false;
          // this.getCities();
          this.states = data.states;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getCities(id) {
      this.loadingCity = true;
      properties
        .fetchCities(id)
        .then((data) => {
          this.loadingCity = false;

          this.cities = data.cities;
        })
        .catch((err) => {
          console.log(err);
        });
    },
   
  },
  computed: {
    sortedStates() {
      return [...this.states].sort((a, b) => {
        if (a.name.toUpperCase() < b.name.toUpperCase()) {
          return -1;
        }
      });
    },
    sortedCity() {
      return [...this.cities].sort((a, b) => {
        if (a.name.toUpperCase() < b.name.toUpperCase()) {
          return -1;
        }
      });
    },
    propertyTypeId() {
      return (
        this.propertyTypes.find((el) => el.type === this.propertyType).id || ""
      );
    },
    propertySubTypeId() {
      return (
        this.propertySubTypes.find((el) => el.subtype === this.propertySubType)
          .id || ""
      );
    },
    stateId() {
      return this.states.find((el) => el.name === this.location).id || "";
    },
    cityId() {
      return this.cities.find((el) => el.name === this.city).id || "";
    },
    sForm1Valid() {
      return (
        this.validateFirstName() &&
        this.validateLastName() &&
        this.validatePhone() &&
        this.validateEmail() &&
        this.validateLandlordAddress() 
      );
    },
  }
};
</script>


<style scoped lang="scss">
.holder {
  background: #ffffff;
  border-radius: 8px;
  padding: 15px;
}

.upload-holder {
  border: 1px solid #E4E7EC;
  padding: 15px;
  border-radius: 6px;
  // margin-bottom: 10px;
}

.rent-info-wrap {
  border: 1px solid #E2E8F0;
  padding: 15px;
  border-radius: 10px;
  margin-bottom: 15px;
  h4 {
    font-family: Lato;
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    text-align: left;
    color: #2B3352;
    margin-bottom: 15px;
  }
}
.info-input {
  border: none;
  width: auto;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 0;
  font-family: Lato;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  color: #2B3352;
  outline: 0;
  box-shadow: none;
  cursor: pointer;
}
.info-rent {
  margin-bottom: 20px;
  h6, h5 {
    font-family: Lato;
    font-size: 14px;
    line-height: 20px;
    text-align: left;
  }
  h6 {
    font-weight: 400;
    color: #555C74;
  }
  h5 {
    font-weight: 600;
    color: #2B3352;
  }
}

.holding-upload {
  display: flex;
  justify-content: space-between;
  align-items: center;
  button {
    border: 1px solid #555C74;
    color: #555C74;
    font-family: Lato;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    text-align: center;
    outline: 0;
    box-shadow: none;
  }
  p {
    font-family: Lato;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    color: #2B3352;
    border: 1px solid #E4E7EC;
    border-radius: 4px;
    padding: 5px;
  }
  h4 {
    font-family: Lato;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: #2B3352;
  }
  h6 {
    margin-top: 10px;
    margin-bottom: 0px;
    font-family: Lato;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    color: #555C74;
  }
}

.property-details-view-holder {
  border: 1px solid #E2E8F0;
  border-radius: 8px;
  padding: 15px;
  h4 {
    font-family: Lato;
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    text-align: left;
    color: #555C74;
    margin-bottom: 20px;
  }
  h6, h5 {
    font-family: Lato;
    font-size: 14px;
    line-height: 20px;
    text-align: left;
  }
  h6 {
    font-weight: 400;
    color: #555C74;
  }
  h5 {
    font-weight: 500;
    color: #2B3352;
  }
}

.detail-tabs-holder {
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;
  margin-top: 25px;
  
}

.detail-tabs {
  background: #F5F6FA;
  border-radius: 50px;
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
  white-space: nowrap;
  padding: 10px 10px;
  button {
    font-family: Lato;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    color: #555C74;
    padding: 10px 20px;
    outline: 0;
    box-shadow: none;
    border-radius: 50px;
    // margin: 0 5px;
    // width: 100%;

  }
}

.active-pills {
  background: #ffffff;
  color: #555C74;
  border-radius: 50px;
}

.no-prop-add {
  margin-top: 30px;
  border: 1px dashed #E4E7EC;
  min-height: 128px;
  background: #FDFDFD;
  text-align: center;
  padding: 20px;
  cursor: pointer;
  h2 {
    color: #555C74;
    font-size: 50px;
    font-family: Lato;
  }
  h6 {
    color: #555C74;
    font-size: 16px;
    font-family: Lato;
    font-weight: 400;
  }
}

.dropdown-menu {
  box-shadow: 0px 4px 8px 0px #2E2E2E1A, 0px 15px 15px 0px #2E2E2E17, 0px 35px 21px 0px #2E2E2E0D, 0px 62px 25px 0px #2E2E2E03, 0px 96px 27px 0px #2E2E2E00;
  background: #ffffff;
  border-radius: 8px;
  border: none;
  left: -78px !important;
}
.dropdown-item {
  font-family: Lato;
  font-size: 16px;
  font-weight: 200;
  line-height: 24px;
  text-align: left;
  color: #555C74;
  margin-bottom: 8px;
  outline: 0;
  box-shadow: none;
}

.indicator {
  display: flex;
  justify-content: left;
  align-items: flex-start;
  gap: 20px;
  h4 {
    width: 48px;
    height: 48px;
    border-radius: 50px;
    background: #F9FAFB;
    padding: 14px;
    font-family: Lato;
    font-size: 16px;
    font-weight: 600;
    // line-height: 28px;
    text-align: center;
    color: #73839B;
  }
}

.prop-review-holder {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  img {
    cursor: pointer;
  }
}
.general-info-content {
  div {
    // margin-bottom: 10px;
    h5 {
      font-family: Lato;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      text-align: left;
      color: #2B3352;
      overflow-wrap: break-word;
    }
    h6 {
      font-family: Lato;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      text-align: left;
      color: #555C74;
      overflow-wrap: break-word;
    }
    h6:last-child {
      margin-bottom: 0;
    }
  }
}

.update-top-text {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 30px;
  h4 {
    font-family: Lato;
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    text-align: left;
    color: #2B3352;
  }
  h6 {
    font-family: Lato;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: #555C74;
  }
  span {
    font-family: Lato;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
    color: #0033EA;
    text-decoration: underline;
  }
}

.info-holder {
  border: 1px solid #E2E8F0;
  border-radius: 8px;
  padding: 20px 15px;
  margin-top: 30px;
}
.review-top-text {
  display: flex;
  justify-content: space-between;
  align-items: center;
  // margin-top: 30px;
  h5 {
    font-family: Lato;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
    color: #2B3352;

  }
  h6 {
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    color: #0033EA;
    cursor: pointer;
    text-decoration: underline;
  }
}
// .general-info-content {
//   div {
//     // margin-bottom: 10px;
//     h5 {
//       font-family: Lato;
//       font-size: 14px;
//       font-weight: 400;
//       line-height: 20px;
//       text-align: left;
//       color: #2B3352;
//       overflow-wrap: break-word;
//     }
//     h6 {
//       font-family: Lato;
//       font-size: 14px;
//       font-weight: 500;
//       line-height: 20px;
//       text-align: left;
//       color: #555C74;
//       overflow-wrap: break-word;
//     }
//     h6:last-child {
//       margin-bottom: 0;
//     }
//   }
// }
.tab-holder {
  border-bottom: 3px solid #E2E8F0;
  button {
    font-family: Lato;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    color: #555C74;
    border-radius: 0;
    outline: 0;
    box-shadow: none;
  }
}
.activeTab {
  color: #0033EA !important;
  border-bottom: 2px solid #0033EA;
}


.btn-container {
  padding: 1rem 0;
  display: flex;
  justify-content: space-between;
}

.flex-end {
  justify-content: flex-end;
}
.container2 {
  background-color: white;
  border-radius: 10px;

  padding: 2rem;
}
.form {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
  gap: 1.5rem;
  font-family: "Lato", sans-serif;
  padding: 5px 0;
}
.input-group {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
.input-group > * {
  flex: 1;
}

// Dynamic input starts here
.unit-count-holder {
  display: flex;
  justify-content: space-between;
  align-items: center;
  h3 {
    font-family: Lato;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: #2B3352;
  }
}
.unit-text-button {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  h6 {
    font-family: Lato;
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    text-align: left;
    color: #2B3352;
  }
  button {
    font-family: Lato;
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    text-align: left;
    color: #0033ea;
    border-bottom: 1px solid #0033ea;
    border-radius: 0;
    box-shadow: none;
    outline: 0;
    padding-left: 0;
    padding-right: 0;
  }
}
.addNewUnit {
  h6 {
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #555C74;
    margin-top: 10px;
  }
}

.dynamic-input {
  padding: 1rem 10px !important;
  border: 1px solid #d2d3d3;
  border-radius: 6px;
  color: #57595a;
  font-size: 1rem;
  font-family: Lato;
  height: inherit;
  outline: 0;
  box-shadow: none;
  // height: 48px;
}
.prop-reviw-holder {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20;
  img {
    cursor: pointer;
  }
}
.general-info-content {
  div {
    margin-bottom: 10px;
    h5 {
      font-family: Lato;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      text-align: left;
      color: #2B3352;
      overflow-wrap: break-word;
    }
    h6 {
      font-family: Lato;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      text-align: left;
      color: #555C74;
      overflow-wrap: break-word;
    }
    h6:last-child {
      margin-bottom: 0;
    }
  }
}

.info-holder {
  border: 1px solid #E2E8F0;
  border-radius: 8px;
  padding: 20px 15px;
}
.review-top-text {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  h5 {
    font-family: Lato;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
    color: #2B3352;

  }
  h6 {
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    color: #0033EA;
  }
}
.no-prop-add {
  margin-top: 30px;
  border: 1px dashed #E4E7EC;
  min-height: 128px;
  background: #FDFDFD;
  text-align: center;
  padding: 20px;
  cursor: pointer;
  h2 {
    color: #555C74;
    font-size: 50px;
    font-family: Lato;
  }
  h6 {
    color: #555C74;
    font-size: 16px;
    font-family: Lato;
    font-weight: 400;
  }
}

.submit-btn:disabled {
  background-color: lightgray;
  cursor: not-allowed;
}

.btn-container {
  padding: 1rem 0;
  display: flex;
  justify-content: space-between;
}

// Dynamic input ends here

@media screen and (min-width: 768px) {
  .form {
    grid-template-columns: 1fr;
  }

  .input-group {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 1.5rem;
  }
}

.form > * {
  align-self: center;
}

label {
  display: block;
}

.green-yellow-btn {
  margin-top: 40px;
  button {
    font-family: Lato;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    color: #ffffff;
    min-height: 48px;
    min-width: 106px;
  }
  .save {
    background: #2BC300;
    margin-right: 20px;
  }
  .cancel {
    background: #EB0000;
  }
}



@media screen and (min-width: 1024px) {
  .steps-container {
    display: block;
  }
}

.forms-container {
  flex: 1;
}

@media screen and (max-width: 599px) { 
 
  .finish-holder {
    margin: 0;
  }
  #progressbar {
    padding-top: 30px;
  }
  #progressbar li {
    font-size: 9px;
  }
  .indicator { 
    gap: 10px;
    h4 {
      width: 40px;
      height: 40px;
      padding: 9px;
      font-size: 14px;
    }
  }
}

</style>